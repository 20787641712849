<template>
    <el-main>
        <el-button style="margin-bottom: 10px" @click="$router.push('/extension/PCOfficialWebsite/addBanner')" size="small" type="primary" icon="el-icon-plus">添加banner</el-button>
        <!-- 表单 -->
        <el-form class="el-form-search" label-width="110px">
            <el-form-item label="banner名称：">
                <el-input size="small" placeholder="请输入banner名称" v-model="searchForm.banner_name"></el-input>
            </el-form-item>
            <el-form-item label="是否显示：">
                <el-select v-model="searchForm.is_show" size="small">
                    <el-option label="全部" value="0"></el-option>
                    <el-option label="否" value="1"></el-option>
                    <el-option label="是" value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label=" " label-width="20px">
                <el-button type="primary" size="small" @click="getList">搜索</el-button>
                <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
            </el-form-item>
        </el-form>
        <!-- 列表 -->
        <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" :key="key" fit>
            <template slot="empty">
                <No />
            </template>
            <el-table-column prop="banner_name" label="banner名称" align="center"></el-table-column>
            <el-table-column prop="banner_picture" label="banner图" align="center">
                <template v-slot="{ row }">
                    <el-image :src="row.banner_picture" alt="" class="banner_img" :previewSrcList="[...row.banner_picture]"></el-image>
                </template>
            </el-table-column>
            <el-table-column prop="banner_location" label="banner位置" align="center"></el-table-column>
            <el-table-column prop="jump_url" label="跳转链接" align="center"></el-table-column>
            <el-table-column prop="is_show" label="是否显示" align="center">
                <template v-slot="{ row }">
                    <el-switch v-model="row.is_show" @change="changeShow($event,row.id)" :active-value="1" :inactive-value="0"></el-switch>
                </template>
            </el-table-column>
            <el-table-column prop="update_time" label="更新时间" align="center" >
                <template v-slot="{ row }">
                    {{row.update_time | getDateformat}}
                </template>
            </el-table-column>
            <el-table-column label="操作"  align="center" fixed="right" >
                <template slot-scope="scope">
                    <el-button @click="$router.push({path:'/extension/PCOfficialWebsite/editBanner',query:{id:scope.row.id}})" type="text" size="small">编辑</el-button>
                    <el-button @click="deleteData(scope.row.id)" type="text" size="small">删除</el-button>
                </template>
            </el-table-column>
        </el-table>

        <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
    </el-main>
</template>

<script>
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
export default {
    components: {
        Paging,
    },
    data() {
        return {
            searchForm: {
                banner_name:'',
                is_show:'0',
                page: 1,
                rows: 10,
            },
            list:[],
            total_number:0,
        };
    },
    filters:{
        getDateformat:getDateformat
    },
    created() {
        this.getList()
    },
    methods: {
        getList(){
            this.$axios.post(this.$api.PCOfficialWebsite.getBannerList,this.searchForm).then(res => {
                if (res.code == 0) {
                    this.list = res.result.list
                    this.total_number = res.result.total_number
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        cancelSearch(){
            this.searchForm = {
                banner_name:'',
                is_show:'0',
                page: 1,
                rows: 10,
            };
            this.getList();
        },
        updateData(val, status) {
            if (status == 0) {
                this.searchForm.rows = val;
                this.getList();
            } else {
                this.searchForm.page = val;
                this.getList();
            }
        },
        deleteData(id){
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios.post(this.$api.PCOfficialWebsite.delBanner,{id}).then(res => {
                    if (res.code == 0) {
                        this.$message.success('删除成功')
                        this.getList()
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            }).catch(() => {});
        },
        changeShow(val,id){
            this.$axios.post(this.$api.PCOfficialWebsite.editBannerInfo,{
                id,
                is_show:val
            }).then(res => {
                if (res.code == 0) {
                    this.$message.success('编辑成功')
                    this.getList()
                } else {
                    this.$message.error(res.msg);
                }
            });
        }
    },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
.banner_img{
    width: 200px;
    height: 70px;
    object-fit: contain;
}
</style>
